import { TimeIcon } from '@/components/svgs';
import { NotificationPing, ProfessionAvatar } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount, getFirstName } from '@/lib/utils';
import { MixpanelEvent, trackEvent } from '@/mixpanel/events';
import { homepage as homepageStrings } from '@/strings';
import { formatAgoShort } from '@/strings/common';

import TaskBlockActions from './TaskBlockActions';
import { TaskBlockProps } from './types';

const strings = homepageStrings.tasks;

function TaskBlock({ taskType, process, event, openCandidateDialog }: TaskBlockProps) {
    const profession = process.candidate.profession as Profession;
    const candidateFirstName = getFirstName(process.candidate);
    const eventDate = new Date(event.endAt ? event.endAt : event.startAt);
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    const onNameClick = () => {
        trackEvent(MixpanelEvent.HomepageClickCandidateName, {
            via: 'pending_task',
            candidate_id: process.candidate.id,
            candidate_name: process.candidate.name,
        });
        openCandidateDialog();
    };

    return (
        <div className={cn('w-full bg-white rounded-2xl p-10 mt-4 first-of-type:mt-0')}>
            <div className={cn('relative flex justify-between items-start gap-2')}>
                <div className={cn('flex items-center')}>
                    <div
                        className={cn('shrink-0 relative h-12 w-12 cursor-pointer')}
                        onClick={onNameClick}
                    >
                        <NotificationPing className={cn('absolute -left-1 -top-1')} />
                        <ProfessionAvatar
                            className={cn('h-full w-full')}
                            profession={profession}
                        />
                    </div>

                    <div className={cn('ml-3')}>
                        <p
                            className={cn(
                                'text-13 font-bold hover:text-primary cursor-pointer transition-colors ease-out duration-200',
                            )}
                            onClick={onNameClick}
                        >
                            {process.candidate.name}
                        </p>
                        <p className={cn('text-11 font-medium')}>
                            {strings.candidateSubtitle.func(
                                profession,
                                facilityName,
                                facilitiesCount,
                            )}
                        </p>
                    </div>
                </div>
                <div className={cn('flex items-center flex-nowrap')}>
                    <p
                        className={cn(
                            'text-neutral300 text-11 leading-[1] font-medium whitespace-nowrap',
                        )}
                    >
                        {formatAgoShort(eventDate)}
                    </p>
                    <TimeIcon
                        className={cn(
                            'shrink-0 h-[11px] w-[11px] fill-neutral300 stroke-neutral300 ml-2',
                        )}
                    />
                </div>
            </div>
            <div className={cn('flex justify-between items-end gap-3 mt-8')}>
                <div>
                    <p className={cn('text-17 font-semibold')}>
                        {strings[taskType].questionTitle.func(candidateFirstName)}
                    </p>
                    <p className={cn('text-13 mt-3')}>
                        {strings[taskType].questionInfo.func(
                            candidateFirstName,
                            eventDate,
                            event.facility?.name ?? '',
                        )}
                    </p>
                </div>
                <TaskBlockActions process={process} taskType={taskType} />
            </div>
        </div>
    );
}

export default TaskBlock;
